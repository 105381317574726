/* eslint-disable react-hooks/exhaustive-deps */
import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import {
  deleteAttrsIncidentByOrgAction,
  loadingAllIncidentAttr,
  loadingListOrgAdminAction,
  updateDetailIncidentAction,
} from 'actions/incidents';
import { Form, Input, Select, DatePicker, Typography, Row, Col, Space, Modal } from 'antd';
import { apiGetListIncidentAttrByOrg } from 'api/incident-attr';
import { showError, showSuccess } from 'components/standby-notice';
import moment from 'moment';
import { useAppSelector } from 'app/hooks';
import isEqual from 'lodash/isEqual';
import {
  IPayloadActionDeleteAttrsIncidentByOrg,
  IPayloadActionUpdateDetailIncident,
  IPayloadGetListAttrAll,
  IPayloadGetOrgAdmin,
  IResponseIncidentDetail,
} from 'pages/incidents/interfaces';
import { INCIDENT_ATTR_TYPES, IPayloadGetListIncidentAttrByOrg } from 'pages/setting/interfaces';
import React from 'react';
import { useDispatch } from 'react-redux';
import { EStatus } from './Notes';
import CloseIcon from 'components/common/icon-svg/close';
import locale from 'antd/es/date-picker/locale/ja_JP';
import get from 'lodash/get';
import { translate } from 'libs';

const { Option } = Select;
const { confirm } = Modal;

interface IFormDetailProps {
  data: IResponseIncidentDetail | null;
  id: string;
  handleShowCloseStatus: (key: any, id: string, code: number) => void;
  getActivityLog: () => void;
  getIncidentDetail: () => void;
  currentRole: string;
  onRef?: any;
}

const FormIncidentDetail = ({
  data,
  id,
  handleShowCloseStatus,
  getActivityLog,
  getIncidentDetail,
  currentRole,
  onRef,
}: IFormDetailProps) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isLoading: boolean = useAppSelector((state) => state.incidents.isLoading);
  const [optionsStatus, setOptionsStatus] = React.useState<any[]>([]);
  const [optionsType, setOptionsType] = React.useState<any[]>([]);
  const [optionsLabel, setOptionsLabel] = React.useState<any[]>([]);
  const [optionsAction, setOptionsAction] = React.useState<any[]>([]);
  const [optionsAssignees, setOptionsAssignees] = React.useState<any[]>([]);
  const [optionsTargets, setOptionsTargets] = React.useState<any[]>([]);
  const [optionsPerpetrators, setOptionsPerpetrators] = React.useState<any[]>([]);
  const [multileSelect, setMultileSelect] = React.useState<object>({});
  const myOrgId: string = data?.org_id?._id || '';
  const [searchTargetValue, setSearchTargetValue] = React.useState<any>('');
  const [searchPerpetratorsValue, setPerpetratorsValue] = React.useState<any>('');

  const assignRef = React.useRef<any>();
  const perRef = React.useRef<any>();
  const targetRef = React.useRef<any>();
  const labelRef = React.useRef<any>();
  const actionRef = React.useRef<any>();
  React.useEffect(() => {
    return () => {
      onRef?.(undefined);
      setOptionsStatus([]);
      setOptionsType([]);
      setOptionsLabel([]);
      setOptionsAction([]);
      setOptionsAssignees([]);
      setOptionsTargets([]);
      setOptionsPerpetrators([]);
      setMultileSelect({});
    };
  }, []);

  const loadingIncidentAtrr = () => {
    const payload: IPayloadGetListAttrAll = {
      query: {
        organization_id: myOrgId,
      },
      onSuccess: (data) => {
        if (data) {
          setValue(data);
        }
      },
      onError: () => {},
    };

    return dispatch(loadingAllIncidentAttr(payload));
  };

  const loadingTargetsByOrg = React.useCallback(async () => {
    const query: IPayloadGetListIncidentAttrByOrg['query'] = {
      organization_id: myOrgId,
      type: INCIDENT_ATTR_TYPES.TARGETS,
    };

    const getListType = await apiGetListIncidentAttrByOrg(query);

    setOptionsTargets(getListType?.data || []);
  }, [data]);

  const loadingPerpetratorsByOrg = React.useCallback(async () => {
    const query: IPayloadGetListIncidentAttrByOrg['query'] = {
      organization_id: myOrgId,
      type: INCIDENT_ATTR_TYPES.PERPETRATORS,
    };

    const getListType = await apiGetListIncidentAttrByOrg(query);

    setOptionsPerpetrators(getListType?.data || []);
  }, [data]);

  const loadingAdmin = () => {
    const payload: IPayloadGetOrgAdmin = {
      query: {
        organization_id: myOrgId,
      },
      onSuccess: (d: any[]) => {
        setOptionsAssignees(d);
        setFormValue();
      },
      onError: () => {},
    };

    return dispatch(loadingListOrgAdminAction(payload));
  };

  React.useEffect(() => {
    if (data) {
      loadingIncidentAtrr();
      loadingTargetsByOrg();
      loadingPerpetratorsByOrg();
      loadingAdmin();
    }
  }, [data?._id]);

  React.useEffect(() => {
    onRef?.(reloadStatus);
  }, [data?.status?.name]);

  const reloadStatus = () => {
    form.setFieldsValue({
      status: data?.status?.name,
    });
  };

  const setFormValue = () => {
    const assigneessMap = data?.assignees.map((e) => `${e?._id}`);
    const labelsMap = data?.labels.map((e) => e?.name);
    const actionsTakenMap = data?.actions_taken.map((e) => e?.name);
    const convertDate = data?.happened_at ? moment(data.happened_at) : '';
    const targetMap = data?.targets.map((e) => e.trim());
    const perpetratorsMap = data?.perpetrators.map((e) => e.trim());

    // init form data

    form.setFieldsValue({
      status: data?.status?.name,
      type: data?.type?.name,
      happened_at: convertDate,
      location: data?.location,
      assignees: assigneessMap || [],
      labels: labelsMap || [],
      targets: targetMap || [],
      perpetrators: perpetratorsMap || [],
      actions_taken: actionsTakenMap || [],
    });
  };

  const setValue = (data: any[]) => {
    setOptionsLabel(data.filter((e) => e?.type === 'LABEL'));
    setOptionsStatus(
      data
        .filter((e) => e?.type === 'STATUS')
        .sort((a, b) => {
          if (a.code && b.code) {
            if (a.code > b.code) {
              return 1;
            }
            if (a.code < b.code) {
              return -1;
            }
          }

          return 0;
        })
    );
    setOptionsType(data.filter((e) => e?.type === 'TYPE'));
    setOptionsAction(data.filter((e) => e?.type === 'ACTION'));
  };

  const loadingUpdateDetailIncident = (updateData: any, key: string | string[]) => {
    if (updateData?.happened_at) {
      updateData = {
        ...updateData,
        happened_at: moment(updateData.happened_at).format('YYYY/MM/DD'),
      };
    }
    if (
      isEqual(data![key as string], updateData[key as string]) ||
      isEqual(get(data![key as string], '_id'), updateData[key as string])
    )
      return;
    const payload: IPayloadActionUpdateDetailIncident = {
      data: updateData,
      onSuccess: () => {
        showSuccess(translate(`incidents.detail.update.${key}.success`));
        setMultileSelect({});
        getActivityLog();
        getIncidentDetail();
      },
      onError: () => {
        showError(translate(`error.common`));
        setFormValue();
      },
    };
    dispatch(updateDetailIncidentAction(payload));
  };

  const handleChangeValue = (value: any, nameField, keyEvent) => {
    const nameStatus: any = optionsStatus.find((item) => item?._id === value?.status);
    const key: string[] = value && Object.keys(value);

    if (
      (nameStatus?.code === EStatus.CLOSED || data?.status?.code === EStatus.CLOSED) &&
      keyEvent === null &&
      nameStatus?.code !== data?.status?.code
    ) {
      if (nameStatus?.code === EStatus.CLOSED) {
        handleShowCloseStatus(EStatus.CLOSED, value?.status, nameStatus?.code);
      } else {
        if (value?.status) {
          handleShowCloseStatus('CHANGE', value.status, nameStatus?.code);
        } else {
          showError(translate('incidents.detail.formDetail.status.message.error'));
        }
      }
      return;
    } else if (
      // show new message and go to screen incidnet list
      nameStatus?.code === EStatus.UNREAD &&
      // data?.status?.code === EStatus.UNANSWERED &&
      keyEvent === null &&
      nameStatus?.code !== data?.status?.code
    ) {
      handleShowCloseStatus(EStatus.UNREAD, value?.status, nameStatus?.code);
      return;
    } else if (
      value !== null &&
      (value.hasOwnProperty('location') ||
        value.hasOwnProperty('assignees') ||
        value.hasOwnProperty('labels') ||
        value.hasOwnProperty('targets') ||
        value.hasOwnProperty('perpetrators') ||
        value.hasOwnProperty('actions_taken'))
    ) {
      checkTrimObject(value);
    } else {
      if (keyEvent) {
        const keys: any = Object.keys(multileSelect);
        if (keys.length > 0) {
          for (const key of keys) {
            let listIdSelectUpdate: any[] = [];
            if (key === 'assignees') {
              multileSelect[key]?.forEach((element: string) => {
                const list = optionsAssignees.find((e) => e.user_id._id === element);

                listIdSelectUpdate = list ? listIdSelectUpdate?.concat(list) : listIdSelectUpdate;
              });

              const listIdAssignees = {
                assignees: listIdSelectUpdate?.map((e) => e?.user_id?._id),
              };
              loadingUpdateDetailIncident(
                {
                  incident_id: id,
                  ...listIdAssignees,
                },
                key
              );
            } else if (key === 'actions_taken') {
              multileSelect[key]?.forEach((element) => {
                const list = optionsAction?.find((e) => e.name === element);
                listIdSelectUpdate = list ? listIdSelectUpdate?.concat(list) : listIdSelectUpdate;
              });
              const listIdAction = { actions_taken: listIdSelectUpdate?.map((e) => e._id) };
              loadingUpdateDetailIncident(
                {
                  incident_id: id,
                  ...listIdAction,
                },
                key
              );
            } else {
              if (key === 'labels') {
                multileSelect[key]?.forEach((element) => {
                  const list = optionsLabel?.find((e) => e.name === element);
                  listIdSelectUpdate = list ? listIdSelectUpdate?.concat(list) : listIdSelectUpdate;
                });
                const listIdLabel = { labels: listIdSelectUpdate?.map((e) => e._id) };
                loadingUpdateDetailIncident(
                  {
                    incident_id: id,
                    ...listIdLabel,
                  },
                  key
                );
              }
            }
          }
        }
        switch (nameField) {
          case 'targets':
            if (!isEqual(value, data?.targets))
              loadingUpdateDetailIncident({ incident_id: id, targets: value }, nameField);
            break;
          case 'perpetrators':
            if (!isEqual(value, data?.perpetrators))
              loadingUpdateDetailIncident({ incident_id: id, perpetrators: value }, nameField);
            break;
          case 'happened_at':
            loadingUpdateDetailIncident({ incident_id: id, happened_at: value }, nameField);
            break;
          case 'location':
            if (value !== data?.location)
              loadingUpdateDetailIncident({ incident_id: id, location: value }, nameField);
            break;
        }
      } else {
        let updateIncidentData;

        if (value.hasOwnProperty('type') && !value.type) {
          updateIncidentData = {
            incident_id: id,
            type: null,
          };
        } else {
          updateIncidentData = {
            incident_id: id,
            ...value,
          };
        }
        loadingUpdateDetailIncident(updateIncidentData, key);
      }
    }
  };

  const checkTrimObject = (value: any) => {
    const key: string[] = Object.keys(value);
    let valueFiled: any = {};
    if (typeof value[key[0]] === 'object') {
      const valueTrim = Object.keys(value[key[0]])
        .map((_, id) => (value[key[0]][id] = value[key[0]][id].trim()))
        .filter((e) => e !== '');
      if (valueTrim.length > 0) {
        valueFiled[key[0]] = valueTrim;
        form.setFieldsValue({ ...valueFiled });
        setMultileSelect({ ...multileSelect, ...valueFiled });
      } else {
        valueFiled[key[0]] = [];
        form.setFieldsValue(valueFiled);
        setMultileSelect(valueFiled);
      }
    } else {
      Object.keys(value).map((k) => (value[k] = value[k].trim()));
      if (value[key[0]].length > 0) {
        setMultileSelect(value);
      } else {
        setMultileSelect(value);
        valueFiled[key[0]] = '';
        form.setFieldsValue(valueFiled);
      }
    }
  };

  const onDeselect = (type) => {
    if (type === INCIDENT_ATTR_TYPES.TARGETS) {
      return targetRef.current.focus();
    } else if (type === INCIDENT_ATTR_TYPES.PERPETRATORS) {
      return perRef.current.focus();
    } else if (type === INCIDENT_ATTR_TYPES.ACTION) {
      return actionRef.current.focus();
    } else if (type === INCIDENT_ATTR_TYPES.LABEL) {
      return labelRef.current.focus();
    } else {
      return assignRef.current.focus();
    }
  };

  const renderIconDeleteItem = (type, text) => {
    const values = form.getFieldValue(type) || [];
    return !values.includes(text);
  };

  const handleDeleteIncidentAttrs = (type, data) => {
    if (data.length === 0) return;
    const callback_ok = () => {
      const payload: IPayloadActionDeleteAttrsIncidentByOrg = {
        data: {
          incident_id: id,
          type,
          attrs: data,
        },
        onSuccess: () => {
          showSuccess(translate(`incidents.detail.update.delete.success`));
          getIncidentDetail();
          if (type === 'perpetrators') {
            loadingPerpetratorsByOrg();
          }
          if (type === 'targets') {
            loadingTargetsByOrg();
          }
        },
        onError: () => {
          showError(translate(`error.common`));
          setFormValue();
        },
      };
      dispatch(deleteAttrsIncidentByOrgAction(payload));
    };
    confirm({
      title: translate('modal.confirm.delete.suggesttion.title'),
      content: translate('modal.confirm.delete.suggesttion'),
      okText: translate('common.close'),
      className: 'modal-confirm-delete-suggestion',
      okType: 'danger',
      icon: null,
      cancelText: translate(`modal.confirm.delete.suggesttion.btn.delete`),
      width: 500,
      onOk() {},
      onCancel() {
        callback_ok();
      },
      okButtonProps: {
        style: { border: '1px solid #E0E0E0', color: '#343434', background: '#ffffff' },
      },
      cancelButtonProps: {
        style: { color: '#ffffff', background: '#FF3B30', borderRadius: '4px', border: 'none' },
      },
    });
  };

  return (
    <>
      <Form
        name="complex-form"
        form={form}
        layout="vertical"
        onValuesChange={(value) => handleChangeValue(value, null, null)}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="status"
              label={translate('incidents.detail.formDetail.label.status')}
              className="hide-content-multi"
            >
              <Select
                disabled={currentRole === 'RO' ? true : false}
                className="select-item"
                placeholder={translate('incidents.detail.formDetail.placeholder.status')}
                size="large"
                dropdownClassName="select-reponse-status"
              >
                {optionsStatus?.map((item: any, index: number) => {
                  const id = item._id;
                  return (
                    <Option value={id} key={index} className="multile-item">
                      {item?.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="type"
              label={translate('incidents.detail.formDetail.label.type')}
              className="hide-content-multi"
            >
              <Select
                disabled={
                  data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false
                }
                className="select-item"
                placeholder={translate('incidents.detail.formDetail.required.message.type')}
                size="large"
                allowClear
              >
                {optionsType?.map((item: any, index: number) => {
                  return (
                    <Option value={item?._id} key={index} className="multile-item">
                      {item?.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="happened_at"
              label={translate('incidents.detail.formDetail.label.date')}
              // className="hide-content-multi"
            >
              <DatePicker
                disabled={
                  data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false
                }
                placeholder={translate('incidents.detail.formDetail.required.message.happen')}
                size="large"
                allowClear
                clearIcon={<CloseCircleFilled style={{ color: 'red' }} />}
                dropdownClassName="date-picker"
                format="YYYY/MM/DD"
                disabledDate={(current: any) => moment().add(0, 'days') < current}
                locale={locale}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="location"
              label={translate('incidents.detail.formDetail.label.location')}
              className="hide-content-multi"
            >
              <Input
                onBlur={() => handleChangeValue(form.getFieldValue('location'), 'location', 'blur')}
                disabled={
                  data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false
                }
                placeholder={translate('incidents.detail.formDetail.required.message.location')}
                size="large"
                maxLength={255}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="assignees" label={translate('incidents.detail.formDetail.label.assign')}>
          <Select
            notFoundContent={translate('no_data', {
              name: translate('incidents.detail.formDetail.label.assign'),
            })}
            onDeselect={() => onDeselect('ASSIGNEES')}
            ref={assignRef}
            disabled={data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false}
            mode="multiple"
            placeholder={translate('incidents.detail.formDetail.required.message.assignees')}
            style={{ width: '100%' }}
            className="select-item box-select-detail"
            onBlur={() => handleChangeValue(form.getFieldValue('assignees'), 'assignees', 'blur')}
            loading={isLoading}
            removeIcon={
              <div className="close-select">
                <CloseIcon />
              </div>
            }
          >
            {optionsAssignees?.map((item: any, index: number) => {
              const fullName: string = `${item.user_id.last_name || ''} ${
                item.user_id.first_name || ''
              }`;
              return (
                <Option value={item.user_id._id} key={item.user_id._id} className="multile-item">
                  <pre className="pre-text">{fullName}</pre>
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="labels" label={translate('incidents.detail.formDetail.label.labels')}>
          <Select
            notFoundContent={translate('no_data', {
              name: translate('incidents.detail.formDetail.label.labels'),
            })}
            onDeselect={() => onDeselect(INCIDENT_ATTR_TYPES.LABEL)}
            ref={labelRef}
            disabled={data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false}
            mode="multiple"
            placeholder={translate('incidents.detail.formDetail.required.message.labels')}
            size="large"
            className="select-item"
            onBlur={() => handleChangeValue(form.getFieldValue('labels'), 'labels', 'blur')}
            removeIcon={
              <div className="close-select">
                <CloseIcon />
              </div>
            }
          >
            {optionsLabel?.map((item: any, index: number) => {
              return (
                <Option value={item?.name} key={index} className="multile-item">
                  {item?.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="targets"
          label={translate('incidents.detail.formDetail.label.targets')}
          shouldUpdate
        >
          <Select
            notFoundContent={translate('no_data', {
              name: translate('incidents.detail.formDetail.label.targets'),
            })}
            onDeselect={() => onDeselect(INCIDENT_ATTR_TYPES.TARGETS)}
            ref={targetRef}
            disabled={data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false}
            removeIcon={
              <div className="close-select">
                <CloseIcon />
              </div>
            }
            onSearch={(e) => {
              setSearchTargetValue(e.substring(0, 255));
            }}
            onBlur={() => handleChangeValue(form.getFieldValue('targets'), 'targets', 'blur')}
            mode="tags"
            placeholder={translate('incidents.detail.formDetail.required.message.targets')}
            // size="large"
            className="select-item"
            showSearch
            autoClearSearchValue
            dropdownClassName="select-dropdown"
            searchValue={searchTargetValue}
            dropdownRender={(menu) => (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Space className="dropdown-addnew" size={4}>
                    <PlusOutlined className="plus-icon" />
                    <Typography.Text className="plus-text">
                      {translate('incidents.detail.formDetail.addnew')}
                    </Typography.Text>
                  </Space>
                  <div
                    className="delete-all-suggestions"
                    onClick={() => {
                      const chooesed = form.getFieldValue('targets') || [];
                      const attrs_no_choose = [...optionsTargets].filter(
                        (p) => !chooesed.includes(p)
                      );
                      handleDeleteIncidentAttrs('targets', attrs_no_choose);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9ZM15.5 4L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4H15.5Z"
                        fill="#FF3B30"
                      />
                    </svg>
                    {translate('delete.all.suggestion')}
                  </div>
                </div>
                {menu}
              </>
            )}
            tagRender={(props) => {
              return (
                <span className="wrap-tag">
                  {props.value}
                  <svg
                    onClick={() => {
                      const list_data = form.getFieldValue('targets') || [];
                      if (list_data.length > 0) {
                        const new_list = list_data.filter((d: string) => d !== props.value);
                        form.setFieldsValue({ targets: new_list });
                        const arr_set = new Set([...optionsTargets, props.value]);
                        setOptionsTargets(Array.from(arr_set));
                      }
                    }}
                    style={{ marginTop: '4px', cursor: 'pointer' }}
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path
                        d="M18.2997 5.70997C17.9097 5.31997 17.2797 5.31997 16.8897 5.70997L11.9997 10.59L7.10973 5.69997C6.71973 5.30997 6.08973 5.30997 5.69973 5.69997C5.30973 6.08997 5.30973 6.71997 5.69973 7.10997L10.5897 12L5.69973 16.89C5.30973 17.28 5.30973 17.91 5.69973 18.3C6.08973 18.69 6.71973 18.69 7.10973 18.3L11.9997 13.41L16.8897 18.3C17.2797 18.69 17.9097 18.69 18.2997 18.3C18.6897 17.91 18.6897 17.28 18.2997 16.89L13.4097 12L18.2997 7.10997C18.6797 6.72997 18.6797 6.08997 18.2997 5.70997Z"
                        fill="black"
                        fill-opacity="0.54"
                      />
                    </g>
                  </svg>
                </span>
              );
            }}
          >
            {optionsTargets?.map((item: string, index: number) => {
              return (
                <Option value={item} key={index} className="multile-item">
                  <pre className="pre-text">{item}</pre>
                  {renderIconDeleteItem('targets', item) && (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteIncidentAttrs('targets', [item]);
                      }}
                    >
                      <g opacity="0.5">
                        <path
                          d="M18.2997 5.70997C17.9097 5.31997 17.2797 5.31997 16.8897 5.70997L11.9997 10.59L7.10973 5.69997C6.71973 5.30997 6.08973 5.30997 5.69973 5.69997C5.30973 6.08997 5.30973 6.71997 5.69973 7.10997L10.5897 12L5.69973 16.89C5.30973 17.28 5.30973 17.91 5.69973 18.3C6.08973 18.69 6.71973 18.69 7.10973 18.3L11.9997 13.41L16.8897 18.3C17.2797 18.69 17.9097 18.69 18.2997 18.3C18.6897 17.91 18.6897 17.28 18.2997 16.89L13.4097 12L18.2997 7.10997C18.6797 6.72997 18.6797 6.08997 18.2997 5.70997Z"
                          fill="black"
                          fill-opacity="0.54"
                        />
                      </g>
                    </svg>
                  )}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="perpetrators"
          label={translate('incidents.detail.formDetail.label.perpetrators')}
        >
          <Select
            notFoundContent={translate('no_data', {
              name: translate('incidents.detail.formDetail.label.perpetrators'),
            })}
            onSearch={(e) => {
              setPerpetratorsValue(e.substring(0, 255));
            }}
            searchValue={searchPerpetratorsValue}
            onDeselect={() => onDeselect(INCIDENT_ATTR_TYPES.PERPETRATORS)}
            ref={perRef}
            disabled={data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false}
            removeIcon={
              <div className="close-select">
                <CloseIcon />
              </div>
            }
            mode="tags"
            onBlur={() =>
              handleChangeValue(form.getFieldValue('perpetrators'), 'perpetrators', 'blur')
            }
            placeholder={translate('incidents.detail.formDetail.required.message.perpetrator')}
            // size="large"
            className="select-item"
            showSearch
            dropdownClassName="select-dropdown"
            tagRender={(props) => {
              return (
                <span className="wrap-tag">
                  {props.value}
                  <svg
                    onClick={() => {
                      const list_data = form.getFieldValue('perpetrators') || [];
                      if (list_data.length > 0) {
                        const new_list = list_data.filter((d: string) => d !== props.value);
                        form.setFieldsValue({ perpetrators: new_list });
                        const arr_set = new Set([...optionsPerpetrators, props.value]);
                        setOptionsPerpetrators(Array.from(arr_set));
                      }
                    }}
                    style={{ marginTop: '4px', cursor: 'pointer' }}
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path
                        d="M18.2997 5.70997C17.9097 5.31997 17.2797 5.31997 16.8897 5.70997L11.9997 10.59L7.10973 5.69997C6.71973 5.30997 6.08973 5.30997 5.69973 5.69997C5.30973 6.08997 5.30973 6.71997 5.69973 7.10997L10.5897 12L5.69973 16.89C5.30973 17.28 5.30973 17.91 5.69973 18.3C6.08973 18.69 6.71973 18.69 7.10973 18.3L11.9997 13.41L16.8897 18.3C17.2797 18.69 17.9097 18.69 18.2997 18.3C18.6897 17.91 18.6897 17.28 18.2997 16.89L13.4097 12L18.2997 7.10997C18.6797 6.72997 18.6797 6.08997 18.2997 5.70997Z"
                        fill="black"
                        fill-opacity="0.54"
                      />
                    </g>
                  </svg>
                </span>
              );
            }}
            dropdownRender={(menu) => (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Space className="dropdown-addnew" size={4}>
                    <PlusOutlined className="plus-icon" />
                    <Typography.Text className="plus-text">
                      {translate('incidents.detail.formDetail.addnew')}
                    </Typography.Text>
                  </Space>
                  <div
                    className="delete-all-suggestions"
                    onClick={() => {
                      const chooesed = form.getFieldValue('perpetrators') || [];
                      const attrs_no_choose = [...optionsPerpetrators].filter(
                        (p) => !chooesed.includes(p)
                      );
                      handleDeleteIncidentAttrs('perpetrators', attrs_no_choose);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9ZM15.5 4L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4H15.5Z"
                        fill="#FF3B30"
                      />
                    </svg>
                    {translate('delete.all.suggestion')}
                  </div>
                </div>
                {menu}
              </>
            )}
          >
            {optionsPerpetrators?.map((item: string, index: number) => {
              return (
                <Option value={item} key={index} className="multile-item">
                  <pre className="pre-text">
                    {item}
                    {renderIconDeleteItem('perpetrators', item) && (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteIncidentAttrs('perpetrators', [item]);
                        }}
                      >
                        <g opacity="0.5">
                          <path
                            d="M18.2997 5.70997C17.9097 5.31997 17.2797 5.31997 16.8897 5.70997L11.9997 10.59L7.10973 5.69997C6.71973 5.30997 6.08973 5.30997 5.69973 5.69997C5.30973 6.08997 5.30973 6.71997 5.69973 7.10997L10.5897 12L5.69973 16.89C5.30973 17.28 5.30973 17.91 5.69973 18.3C6.08973 18.69 6.71973 18.69 7.10973 18.3L11.9997 13.41L16.8897 18.3C17.2797 18.69 17.9097 18.69 18.2997 18.3C18.6897 17.91 18.6897 17.28 18.2997 16.89L13.4097 12L18.2997 7.10997C18.6797 6.72997 18.6797 6.08997 18.2997 5.70997Z"
                            fill="black"
                            fill-opacity="0.54"
                          />
                        </g>
                      </svg>
                    )}
                  </pre>
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="actions_taken"
          label={translate('incidents.detail.formDetail.label.action_taken')}
        >
          <Select
            notFoundContent={translate('no_data', {
              name: translate('incidents.detail.formDetail.label.action_taken'),
            })}
            onDeselect={() => onDeselect(INCIDENT_ATTR_TYPES.ACTION)}
            ref={actionRef}
            disabled={data?.status?.code === EStatus.CLOSED || currentRole === 'RO' ? true : false}
            mode="multiple"
            size="large"
            className="select-item"
            placeholder={translate('incidents.detail.formDetail.required.message.actions_taken')}
            onBlur={() =>
              handleChangeValue(form.getFieldValue('actions_taken'), 'actions_taken', 'blur')
            }
            removeIcon={
              <div className="close-select">
                <CloseIcon />
              </div>
            }
          >
            {optionsAction?.map((item: any, index: number) => {
              return (
                <Option value={item?.name} key={index} className="multile-item">
                  {item?.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </>
  );
};

export default FormIncidentDetail;
