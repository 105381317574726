import {
  IActionLoadingIncidents,
  ICreateIncidentsAction,
  IPayloadCreateIncidents,
  IPayloadLoadingIncidents,
  IPayloadGetDetail,
  IActionGetDetail,
  IPayloadGetOrgAdmin,
  IActionGetOrgAdmin,
  IPayloadActionUpdateDetailIncident,
  IActionUpdateDetailIncident,
  IPayloadSearch,
  IActionSearch,
  IPayloadGetActivityLog,
  IPayloadLoadingNotesIncidents,
  IActionLoadingNotesIncidents,
  IPayloadCreateNotesIncidents,
  IActionCreateNotesIncidents,
  IPayloadShareIncidents,
  IActionShareIncidents,
  IActionGetActivityLog,
  IPayLoadDeactiveUser,
  IActionDeactiveUser,
  IPayloadLoadingTotalInfoIncidents,
  IActionLoadingTotalInfoIncidents,
  IPayloadLoadingChatIncidents,
  IActionLoadingChatIncidents,
  IPayloadReadChatIncidents,
  IActionReadChatIncidents,
  IActionAppendMessageIncidents,
  IPayloadAppendMessageIncidents,
  IActionLoadingOtherIncidents,
  IPayloadLoadingOtherIncidents,
  EFilterResponseStatusIncidents,
  IPayloadGetListAttrAll,
  IActionGetListAttrAll,
  IPayloadActionDeleteAttrsIncidentByOrg,
  IActionDeleteAttrsIncidentByOrg,
  IPayloadDeleteChatIncidents,
  IActionDeleteChatIncidents,
} from 'pages/incidents/interfaces';

export const UNMOUT_INCIDENTS_LIST = 'UNMOUT_INCIDENTS_LIST';
export const LOADING_INCIDENTS_DETAIL = 'LOADING_INCIDENTS_DETAIL';
export const LOADING_INCIDENTS_DETAIL_LIST_ORG_ADMIN = 'LOADING_INCIDENTS_DETAIL_LIST_ORG_ADMIN';
export const LOADING_INCIDENTS_DETAIL_SEARCH_TARGETS = 'LOADING_INCIDENTS_DETAIL_SEARCH_TARGETS';

export const LOADING_ACTIVITYLOG = 'LOADING_ActivityLOG';
export const LOADING_ACTIVITYLOG_SUCCESS = 'LOADING_ActivityTYLOG_SUCCESS';
export const LOADING_ACTIVITYLOG_FAILED = 'LOADING_ActivityTYLOG_FAILED';

export const LOADING_INCIDENTS = 'LOADING_INCIDENTS';
export const LOADING_INCIDENTS_SUCCESS = 'LOADING_INCIDENTS_SUCCESS';
export const LOADING_INCIDENTS_FAILED = 'LOADING_INCIDENTS_FAILED';

export const LOADING_INCIDENTS_AT_NOTICE = 'LOADING_INCIDENTS_AT_NOTICE';

export const DEACTIVE_USER = 'DEACTIVE_USER';
export const DEACTIVE_USER_SUCCESS = 'DEACTIVE_USER_SUCCESS';
export const DEACTIVE_USER_FAILED = 'DEACTIVE_USER_FAILED';

export const CREATE_INCIDENTS = 'CREATE_INCIDENTS';
export const CREATE_INCIDENTS_SUCCESS = 'CREATE_INCIDENTS_SUCCESS';
export const CREATE_INCIDENTS_FAILED = 'CREATE_INCIDENTS_FAILED';

export const UPDATE_INCIDENTS = 'UPDATE_INCIDENTS';
export const DELETE_ATTRS_INCIDENTS_BY_ORG = 'DELETE_ATTRS_INCIDENTS_BY_ORG';
export const UPDATE_INCIDENTS_SUCCESS = 'UPDATE_INCIDENTS_SUCCESS';
export const UPDATE_INCIDENTS_FAILED = 'UPDATE_INCIDENTS_FAILED';

export const CREATE_NOTE_INCIDENTS = 'CREATE_NOTE_INCIDENTS';
export const CREATE_NOTE_INCIDENTS_SUCCESS = 'CREATE_NOTE_INCIDENTS_SUCCESS';
export const CREATE_NOTE_INCIDENTS_FAILED = 'CREATE_NOTE_INCIDENTS_FAILED';

export const LOADING_NOTE_INCIDENTS = 'LOADING_NOTE_INCIDENTS';
export const LOADING_NOTE_INCIDENTS_SUCCESS = 'LOADING_NOTE_INCIDENTS_SUCCESS';
export const LOADING_NOTE_INCIDENTS_FAILED = 'LOADING_NOTE_INCIDENTS_FAILED';

export const SHARE_INCIDENTS = 'SHARE_INCIDENTS';
export const SHARE_INCIDENTS_SUCCESS = 'SHARE_INCIDENTS_SUCCESS';
export const SHARE_INCIDENTS_FAILED = 'SHARE_INCIDENTS_FAILED';

export const LOADING_OTHER_INCIDENTS = 'LOADING_OTHER_INCIDENTS';
export const LOADING_OTHER_INCIDENTS_SUCCESS = 'LOADING_OTHER_INCIDENTS_SUCCESS';
export const LOADING_OTHER_INCIDENTS_FAILED = 'LOADING_OTHER_INCIDENTS_FAILED';

export const LOADING_ALL_INCIDENTS_ATTR = 'LOADING_ALL_INCIDENTS_ATTR';
export const LOADING_ALL_INCIDENTS_ATTR_SUCCESS = 'LOADING_ALL_INCIDENTS_ATTR_SUCCESS';
export const LOADING_ALL_INCIDENTS_ATTR_FAILED = 'LOADING_ALL_INCIDENTS_ATTR_FAILED';

export const LOADING_TOTAL_INFO_INCIDENTS = 'LOADING_TOTAL_INFO_INCIDENTS';

export const LOADING_CHAT_INCIDENTS = 'LOADING_CHAT_INCIDENTS';
export const LOADING_CHAT_INCIDENTS_SUCCESS = 'LOADING_CHAT_INCIDENTS_SUCCESS';
export const LOADING_CHAT_INCIDENTS_FAILED = 'LOADING_CHAT_INCIDENTS_FAILED';

export const READ_CHAT_INCIDENTS = 'READ_CHAT_INCIDENTS';
export const READ_CHAT_INCIDENTS_SUCCESS = 'READ_CHAT_INCIDENTS_SUCCESS';
export const READ_CHAT_INCIDENTS_FAILED = 'READ_CHAT_INCIDENTS_FAILED';

export const APPEND_MESSAGE_INCIDENT = 'APPEND_MESSAGE_INCIDENT';
export const APPEND_MESSAGE_INCIDENT_SUCCESS = 'APPEND_MESSAGE_INCIDENT_SUCCESS';

export const RESET_CHAT = 'RESET_CHAT';
export const CHANGE_CHAT_STATUS = 'CHANGE_CHAT_STATUS';

export const EXPORT_FILE_PDF = 'EXPORT_FILE_PDF';
export const EXPORT_FILE_PDF_SUCCESS = 'EXPORT_FILE_PDF_SUCCESS';
export const EXPORT_FILE_PDF_FAILED = 'EXPORT_FILE_PDF_FAILED';

export const LOAD_TOTAL_INFO_SUCCESS = 'LOAD_TOTAL_INFO_SUCCESS';

export const UPDATE_LIST_INCIDENTS_COLUMNS = 'UPDATE_LIST_INCIDENTS_COLUMNS';

export const UPDATE_INCIDENT_FILTER = 'UPDATE_INCIDENT_FILTER';
export const UPDATE_INCIDENT_FILTER_SUCCESS = 'UPDATE_INCIDENT_FILTER_SUCCESS';
export const UPDATE_INCIDENT_FILTER_FAILED = 'UPDATE_INCIDENT_FILTER_FAILED';


export const DELETE_CHAT_INCIDENTS = 'DELETE_CHAT_INCIDENTS';
export const DELETE_CHAT_INCIDENTS_SUCCESS = 'DELETE_CHAT_INCIDENTS_SUCCESS';
export const DELETE_CHAT_INCIDENTS_FAILED = 'DELETE_CHAT_INCIDENTS_FAILED';

export const loadingIncidentsAction = (
  payload: IPayloadLoadingIncidents
): IActionLoadingIncidents => ({
  type: LOADING_INCIDENTS,
  payload,
});

export const loadingIncidentsAtNoticeAction = (
  payload: IPayloadLoadingIncidents
): IActionLoadingIncidents => ({
  type: LOADING_INCIDENTS_AT_NOTICE,
  payload,
});

export const createIncidentsAction = (
  payload: IPayloadCreateIncidents
): ICreateIncidentsAction => ({
  type: CREATE_INCIDENTS,
  payload,
});

export const loadingIncidentsDetailAction = (payload: IPayloadGetDetail): IActionGetDetail => ({
  type: LOADING_INCIDENTS_DETAIL,
  payload,
});

export const loadingListOrgAdminAction = (payload: IPayloadGetOrgAdmin): IActionGetOrgAdmin => ({
  type: LOADING_INCIDENTS_DETAIL_LIST_ORG_ADMIN,
  payload,
});

export const updateDetailIncidentAction = (
  payload: IPayloadActionUpdateDetailIncident
): IActionUpdateDetailIncident => ({
  type: UPDATE_INCIDENTS,
  payload,
});

export const deleteAttrsIncidentByOrgAction = (
  payload: IPayloadActionDeleteAttrsIncidentByOrg
): IActionDeleteAttrsIncidentByOrg => ({
  type: DELETE_ATTRS_INCIDENTS_BY_ORG,
  payload,
});

export const loadingSearchTargetsAction = (payload: IPayloadSearch): IActionSearch => ({
  type: LOADING_INCIDENTS_DETAIL_SEARCH_TARGETS,
  payload,
});

export const loadingActivityLogIncidentAction = (
  payload: IPayloadGetActivityLog
): IActionGetActivityLog => ({
  type: LOADING_ACTIVITYLOG,
  payload,
});
export const loadingNotesIncidentsAction = (
  payload: IPayloadLoadingNotesIncidents
): IActionLoadingNotesIncidents => ({
  type: LOADING_NOTE_INCIDENTS,
  payload,
});

export const createNotesIncidentsAction = (
  payload: IPayloadCreateNotesIncidents
): IActionCreateNotesIncidents => ({
  type: CREATE_NOTE_INCIDENTS,
  payload,
});

export const shareIncidentsAction = (payload: IPayloadShareIncidents): IActionShareIncidents => ({
  type: SHARE_INCIDENTS,
  payload,
});

export const postDeactiveUserAction = (payload: IPayLoadDeactiveUser): IActionDeactiveUser => ({
  type: DEACTIVE_USER,
  payload,
});

export const loadingTotalInfoIncidentsAction = (
  payload: IPayloadLoadingTotalInfoIncidents
): IActionLoadingTotalInfoIncidents => ({
  type: LOADING_TOTAL_INFO_INCIDENTS,
  payload,
});

export const loadingOtherIncidentsAction = (
  payload: IPayloadLoadingOtherIncidents
): IActionLoadingOtherIncidents => ({
  type: LOADING_OTHER_INCIDENTS,
  payload,
});

export const loadingAllIncidentAttr = (payload: IPayloadGetListAttrAll): IActionGetListAttrAll => ({
  type: LOADING_ALL_INCIDENTS_ATTR,
  payload,
});

// Chat
export const loadingChatIncidentsAction = (
  payload: IPayloadLoadingChatIncidents
): IActionLoadingChatIncidents => ({
  type: LOADING_CHAT_INCIDENTS,
  payload,
});

export const appendMessageAction = (
  payload: IPayloadAppendMessageIncidents
): IActionAppendMessageIncidents => ({
  type: APPEND_MESSAGE_INCIDENT,
  payload,
});

export const resetChatAction = () => ({ type: RESET_CHAT });

export const changeStatusChatAction = (payload: EFilterResponseStatusIncidents | string) => ({
  type: CHANGE_CHAT_STATUS,
  payload,
});

export const readChatIncidentsAction = (
  payload: IPayloadReadChatIncidents
): IActionReadChatIncidents => ({
  type: READ_CHAT_INCIDENTS,
  payload,
});

export const updateIncidentFilterAction = (payload: any) => ({
  type: UPDATE_INCIDENT_FILTER,
  payload,
});

export const deleteIncidentsAction = (
  payload: IPayloadDeleteChatIncidents
): IActionDeleteChatIncidents => ({
  type: DELETE_CHAT_INCIDENTS,
  payload,
});