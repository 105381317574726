/* eslint-disable react-hooks/exhaustive-deps */
import {
  Space,
  Typography,
  Form,
  Input,
  Spin,
  Comment,
  Popover,
  notification,
  Tooltip,
  Button,
  Modal,
} from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  EFilterResponseStatusIncidents,
  IPayloadAppendMessageIncidents,
  IPayloadDeleteChatIncidents,
  IPayloadLoadingChatIncidents,
  IPayloadReadChatIncidents,
  IResponseIncidentDetail,
} from 'pages/incidents/interfaces';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import Picker from 'emoji-picker-react';
import { ModalConfirm } from 'components/modal-confirm';
import {
  disconnectSocket,
  initialSocketChat,
  leaveRoom,
  onHandleErrorSocket,
  onReceiveMessage,
  sendMessage,
} from 'libs/socket';
import { useParams } from 'react-router';
import {
  appendMessageAction,
  changeStatusChatAction,
  deleteIncidentsAction,
  loadingChatIncidentsAction,
  readChatIncidentsAction,
  resetChatAction,
} from 'actions/incidents';
import { storage } from 'utils';
import { EStatus } from './Notes';
import trim from 'lodash/trim';
import get from 'lodash/get';
import Drag from './Drag';
import { translate } from 'libs';
import { EllipsisOutlined } from '@ant-design/icons';
import { Resizable } from 're-resizable';
// import { Picker } from 'emoji-mart';

interface IChatProps {
  isVisible: boolean;
  isDisable: boolean;
  onCancel: () => void;
  data: IResponseIncidentDetail | null;
  defaultVisibleChat: boolean;
  currentRole: string;
}

const Chat = ({
  isDisable,
  isVisible,
  onCancel,
  data,
  defaultVisibleChat = false,
  currentRole,
}: IChatProps) => {
  const [form] = Form.useForm();
  const { id }: any = useParams();
  const dispatch = useAppDispatch();

  const inputRef: any = React.useRef();
  const profile: any = useAppSelector((state) => state.auth.profile);
  const statusChats: string = useAppSelector((state) => state.incidents.statusChats);
  const chats: any[] = useAppSelector((state) => state.incidents.chats);

  const [isMinimize, setIsMinimize] = React.useState<boolean>(false);
  const [isVisibleClose, setIsVisibleClose] = React.useState<boolean>(false);
  const [isDisableInputChat, setIsDisableInputChat] = React.useState<boolean>(false);
  const [sendingMessage, setSendingMessage] = React.useState<boolean>(false);
  const [isVisibleDelete, setIsVisibleDelete] = React.useState<boolean>(false);

  const [messageSelectedDelete, setMessageSelectedDelete] = React.useState<any>();
  const [messageIdDelete, setMessageIdDelete] = React.useState<any>();

  const isLoading: boolean = useAppSelector((state) => state.questions.isLoading);
  const isClosed: boolean = data?.status?.code === EStatus.CLOSED;

  const initialChatRoom = () =>
    new Promise<void>((resolve) => {
      const token: string = storage.getToken();

      if (id && token) {
        initialSocketChat(token, id);
      }
      resolve();
    });

  const loadingChats = React.useCallback(() => {
    const payload: IPayloadLoadingChatIncidents = {
      incident_id: id,
    };

    return dispatch(loadingChatIncidentsAction(payload));
  }, [id]);

  React.useEffect(() => {
    if (!defaultVisibleChat) {
      loadingChats();
    }

    return () => {
      dispatch(resetChatAction());
    };
  }, []);

  React.useEffect(() => {
    if (isVisible) {
      loadingChats();

      initialChatRoom().then(() => {
        onReceiveMessage((msg: any) => {
          const payload: IPayloadAppendMessageIncidents = {
            msg,
            onSuccess: () => {
              if (msg.is_admin && msg.sender_id) {
                form.resetFields();
                setSendingMessage(false);
              }
              inputRef?.current?.focus?.();
              changeChatStatus(EFilterResponseStatusIncidents.READ_ANSWERED);
              if (!msg?.is_admin) {
                handleReadMessage();
              }
            },
          };
          return dispatch(appendMessageAction(payload));
        });
        onHandleErrorSocket((msg: any) => {
          if (msg) {
            switch (get(msg, 'response.error_code')) {
              case 'IC1007':
                notification.error({
                  message: translate(`incidents.detail.listDetail.header.text.closed`),
                });
                setSendingMessage(false);
                form.resetFields();
                setIsDisableInputChat(true);
                break;
              // others case
              default:
                break;
            }
          }
        });
      });
    }

    return () => {
      if (isVisible) {
        leaveRoom(id);
        disconnectSocket();
      }
    };
  }, [isVisible]);

  const changeChatStatus = (status: EFilterResponseStatusIncidents | string) =>
    dispatch(changeStatusChatAction(status));

  React.useEffect(() => {
    if (data?.response_status) {
      changeChatStatus(data.response_status);
    }

    if (data?.status?.code === EStatus.CLOSED) {
      form.resetFields();
      setSendingMessage(false);
    }
  }, [data]);

  const handleConfirmClose = () => {
    form.resetFields();
    setSendingMessage(false);
    handleCancelClose();
    setTimeout(() => {
      onCancel();
    }, 300);
  };

  const handleVisibleClose = (event: any) => {
    event.stopPropagation();
    setIsVisibleClose(true);
  };

  const handleCancelClose = () => {
    setIsVisibleClose(false);
  };

  const onClickClose = (event: any) => {
    const textInput: string = form.getFieldValue('message');

    if (!textInput) {
      return handleConfirmClose();
    }

    return handleVisibleClose(event);
  };

  const onEmojiClick = (event: any, emojiObject: any) => {
    const messageValue: string = form.getFieldValue('message');

    const newMessage: string = `${messageValue} ${emojiObject?.emoji} `;

    if (!isClosed) {
      form.setFieldsValue({ message: newMessage });
    }
  };

  const fetchMoreData = () => {
    console.log('🚀 ~ fetchMoreData');
  };

  const onMinimizeChat = (event: any) => {
    setIsMinimize(true);
  };

  const renderIconChat = () => {
    let src: string = '';
    switch (statusChats) {
      case EFilterResponseStatusIncidents.READ_ANSWERED:
        src = '/images/ic-read-anwered.png';
        break;
      case EFilterResponseStatusIncidents.READ_UNANSWERED:
        src = '/images/ic-read-unanwered.png';
        break;
      default:
        src = '/images/ic-unread.png';
        break;
    }

    return <img alt="" src={src} />;
  };

  const onSendMessage = (values) => {
    if (!values || sendingMessage) return;
    let currentMessage: any;

    if (typeof values === 'object') {
      currentMessage = values?.message;
    } else {
      currentMessage = values;
    }

    const trimMessage: string = trim(currentMessage);
    if (isClosed) {
      form.resetFields();
      setSendingMessage(false);
      setIsDisableInputChat(true);
      return notification.error({
        message: translate(`incidents.detail.listDetail.header.text.closed`),
      });
    }

    if (trimMessage.length) {
      setSendingMessage(true);
      sendMessage(id, trimMessage);
    }
  };

  const handleReadMessage = () => {
    if (isMinimize || chats.length === 0) return;
    const payload: IPayloadReadChatIncidents = {
      values: {
        incident_id: id,
      },
    };
    const lastItemChat: any = chats[0];
    if (
      (!lastItemChat?.is_admin || // anonymous chat
        (lastItemChat?.is_admin && !lastItemChat?.sender_id)) && // autoresponse
      statusChats !== EFilterResponseStatusIncidents.READ_UNANSWERED
    ) {
      changeChatStatus(EFilterResponseStatusIncidents.READ_UNANSWERED);
      return dispatch(readChatIncidentsAction(payload));
    }
  };

  const handleDeleteMessage = (item) => {
    if (isMinimize || chats.length === 0) return;

    setMessageSelectedDelete(item);
    setIsVisibleDelete(true);
  };
  const submitMessage = () => form.submit();
  const pickerEmoji = <Picker onEmojiClick={onEmojiClick} />;

  const onDeleteChat = () => {
    const payload: IPayloadDeleteChatIncidents = {
      values: {
        incident_id: id,
        chat_message_id: messageSelectedDelete?._id,
      },
    };

    dispatch(deleteIncidentsAction(payload));
    setIsVisibleDelete(false);
    setTimeout(() => {
      loadingChats();
    }, 500);
    return;
  };

  const handleCancelDelete = () => {
    setIsVisibleDelete(false);
  };
  if (!isVisible) return null;
  return (
    <Drag
      onMinimizeChat={onMinimizeChat}
      onClickClose={onClickClose}
      onClick={() => {
        if (isMinimize) {
          setIsMinimize(false);
        }
      }}
      header={
        <Space size={10}>
          {renderIconChat()}
          <Typography.Text className="title">{`${translate('incidents.detail.chat')} (${
            chats.length
          })`}</Typography.Text>
        </Space>
      }
      isMinimize={isMinimize}
    >
      {!isMinimize && (
        <React.Fragment>
          <div className="chat-content" id="scrollableDiv" onClick={handleReadMessage}>
            <InfiniteScroll
              dataLength={chats.length}
              next={fetchMoreData}
              hasMore={false}
              loader={<Spin />}
              // height={445}
              scrollableTarget="scrollableDiv"
              inverse
              className="inifinite-scroll"
            >
              {chats.map((item: any) => {
                const isOwner: boolean = item?.is_admin || item?.sender_id?._id === profile?._id;

                return (
                  <Comment
                    key={item?._id}
                    content={
                      <Space
                        onMouseEnter={() => {
                          setMessageIdDelete(item);
                        }}
                        //  onMouseLeave={() => setMessageIdDelete(null)}
                      >
                        {!item?.is_deleted &&
                          isOwner &&
                          item?._id === messageIdDelete?._id &&
                          moment(item?.createdAt) >= moment().add(-1, 'hours') && (
                            <Popover
                              placement="bottomRight"
                              className="popover-msg-action"
                              trigger="click"
                              content={
                                <div className="msg-action">
                                  <a className="action" onClick={() => handleDeleteMessage(item)}>
                                    {' '}
                                    <img
                                      alt=""
                                      src="/images/ic-delete-red.png"
                                      className="ic-delete"
                                    />
                                    送信取消
                                  </a>
                                </div>
                              }
                            >
                              <Button
                                shape="circle"
                                size="small"
                                icon={<EllipsisOutlined />}
                                type="primary"
                              ></Button>
                            </Popover>
                          )}
                        <Space
                          size={0}
                          direction="vertical"
                          className={
                            !item?.is_deleted
                              ? 'space-content-bubble'
                              : 'space-content-bubble is-delete'
                          }
                        >
                          {!item?.is_deleted && (
                            <div className="container_text">
                              <Typography.Text className="txt-message">
                                <div>
                                  {item?.files
                                    ? translate('incidents.detail.chat.attachment', {
                                        number: item?.files,
                                      })
                                    : ''}
                                </div>
                                <div>{item?.text ? item?.text : ''}</div>
                              </Typography.Text>
                            </div>
                          )}

                          {item?.is_deleted && (
                            <div className="container_text">
                              <Typography.Text className="txt-message ">
                                <img alt="" src="/images/trash.png" className="ic-delete" />
                                <div>{translate('incidents.detail.chat.deletedText')}</div>
                              </Typography.Text>
                            </div>
                          )}

                          <Space
                            split="·"
                            size={4}
                            className={
                              isOwner ? 'space-info-bubble ' : 'space-info-bubble anonymouse'
                            }
                          >
                            {isOwner && (
                              <Tooltip
                                className="message-name"
                                placement="topLeft"
                                title={
                                  item?.sender_id
                                    ? `${item?.sender_id?.last_name || ''} ${
                                        item?.sender_id?.first_name || ''
                                      }`
                                    : translate('title.chat.automatedResponse')
                                }
                              >
                                <Typography.Text ellipsis>
                                  {item?.sender_id
                                    ? `${item?.sender_id?.last_name || ''} ${
                                        item?.sender_id?.first_name || ''
                                      }`
                                    : translate('title.chat.automatedResponse')}
                                </Typography.Text>
                              </Tooltip>
                            )}
                            <Typography.Text>
                              {moment(item?.createdAt).format('YYYY/MM/DD HH:mm:ss')}
                            </Typography.Text>
                            {isOwner && (
                              <Typography.Text>
                                {translate(
                                  `incidents.detail.chat.${item?.anonymous_read ? 'read' : 'sent'}`
                                )}
                              </Typography.Text>
                            )}
                          </Space>
                          {item?.is_deleted && (
                            <Space
                              split="·"
                              size={4}
                              className={'space-info-bubble  space-info-bubble-delete'}
                            >
                              <Tooltip
                                className="message-name"
                                placement="topLeft"
                                title={
                                  item?.delete_sender_id
                                    ? `${item?.delete_sender_id?.last_name || ''} ${
                                        item?.delete_sender_id?.first_name || ''
                                      }`
                                    : translate('title.chat.automatedResponse')
                                }
                              >
                                <Typography.Text ellipsis>
                                  {item?.delete_sender_id
                                    ? `${item?.delete_sender_id?.last_name || ''} ${
                                        item?.delete_sender_id?.first_name || ''
                                      }`
                                    : translate('title.chat.automatedResponse')}
                                </Typography.Text>
                              </Tooltip>

                              <Typography.Text>
                                {moment(item?.deletedAt).format('YYYY/MM/DD HH:mm:ss')}
                              </Typography.Text>

                              <Typography.Text>
                                {translate(`incidents.detail.chat.deleted`)}
                              </Typography.Text>
                            </Space>
                          )}
                        </Space>
                        {isOwner && (
                          <img
                            alt=""
                            src={`/images/ic-check-${item?.anonymous_read ? 'read' : 'sent'}.png`}
                          />
                        )}
                      </Space>
                    }
                    className={`bubble-chat ${isOwner && 'owner'}`}
                  />
                );
              })}
            </InfiniteScroll>
          </div>
          <Resizable
            enable={{
              top: true,
              right: false,
              bottom: false,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
            minHeight={65}
            maxHeight={220}
          >
            <Form
              form={form}
              initialValues={{ message: '' }}
              onFinish={onSendMessage}
              onClick={handleReadMessage}
              className={'chat-div'}
            >
              {!isDisable &&
                currentRole !== 'RO' &&
                !isDisableInputChat &&
                data?.active_user_id?.is_active === true &&
                data?.active_user_id?.is_logged_out === false && (
                  <Space className={'space-input-message '}>
                    <Popover
                      content={pickerEmoji}
                      trigger="click"
                      zIndex={99}
                      destroyTooltipOnHide={false}
                    >
                      <img alt="" src="/images/ic-emoji.png" className="ic-emoji" />
                    </Popover>
                    <Form.Item name="message" noStyle>
                      <Input.TextArea
                        className="input-message"
                        placeholder={translate('incidents.detail.chat.writeMsg')}
                        ref={inputRef}
                        maxLength={12000}
                        //  autoSize={{ minRows: 3, maxRows: 3 }}
                        disabled={
                          isDisableInputChat ||
                          data?.active_user_id?.is_active === false ||
                          data?.active_user_id?.is_logged_out === true
                        }
                      />
                    </Form.Item>
                    <img
                      alt=""
                      src="/images/ic-send.png"
                      className="ic-send"
                      onClick={submitMessage}
                    />
                  </Space>
                )}
            </Form>
          </Resizable>
        </React.Fragment>
      )}
      <ModalConfirm
        visible={isVisibleClose}
        handleOk={handleConfirmClose}
        handleCancel={handleCancelClose}
        title={translate('incidents.detail.chat.close')}
        textConfirmBtn={translate('incidents.detail.chat.closeConfirm.btn')}
        textConfirm={translate('incidents.detail.chat.closeConfirm')}
      />

      <Modal
        // title={modalTitle}
        maskClosable={false}
        centered
        visible={isVisibleDelete}
        onCancel={handleCancelDelete}
        closable={false}
        width={344}
        footer={[
          <Button
            key="large"
            type="primary"
            className="btn-save"
            size="large"
            danger
            onClick={onDeleteChat}
            loading={isLoading}
          >
            {translate('chat.deleteBtn')}
          </Button>,
          <Button key="cancel" className="btn-cancel" size="large" onClick={handleCancelDelete}>
            {translate('common.cancelBtn')}
          </Button>,
        ]}
        className="modal modal-delete-chat"
      >
        {translate('incidents.detail.chat.modal.content')}
      </Modal>
      {/* <ConfirmDeleteModal
        isVisible={isVisibleDelete}
        handleDelete={onDeleteChat}
        handleCancel={handleCancelDelete}
        //  modalTitle={translate('settingPage.incidentQuestion.delete')}
        confirmText={translate('settingPage.incidentQuestion.sure')}
        deleteButtonTitle={translate('settingPage.incidentQuestion.deleteBtn')}
        isLoading={isLoading}
      /> */}
    </Drag>
  );
};

export default Chat;
